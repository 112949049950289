<template>
  <TimelineComponent>
    <template v-slot:eventExtra1>
      <p>מקום בים, אשקלון</p>
      <div v-if="isMobile()">
        <a href="waze://waze.com/ul?preview_venue_id=22675773.226495585.414470&navigate=yes&utm_campaign=default&utm_source=waze_website&utm_medium=lm_share_location">
          <img src="./assets/waze_logo.svg" height="32px" />
        </a>
        <a href="maps://maps.app.goo.gl/zBpgwDpa2kVddA4h6">
          <img src="./assets/google-maps.png" height="32px" />
        </a>
      </div>
      <div v-else>
        <a href="https://waze.com/ul?preview_venue_id=22675773.226495585.414470&navigate=yes&utm_campaign=default&utm_source=waze_website&utm_medium=lm_share_location">
          <img src="./assets/waze_logo.svg" height="32px" />
        </a>
        <a href="https://maps.app.goo.gl/zBpgwDpa2kVddA4h6">
          <img src="./assets/google-maps.png" height="32px" />
        </a>
      </div>
    </template>
    <template v-slot:eventExtra2>
      <!-- You can include another component here -->
    </template>
    <template v-slot:eventExtra3>
      <!-- You can include another component here -->
    </template>
    <template v-slot:eventExtra4>
      <!-- You can include another component here -->
    </template>
    <template v-slot:whatsapp_group>
      <div style="direction: rtl">
        לקבוצת הסעות
        <a href="https://chat.whatsapp.com/LwzIp4LdkKL7QaDxhJzhdJ">
          <img src="./assets/whatsapp.png" height="32px" />
        </a>
      </div>
    </template>
    <!-- ...other slots for additional events -->
  </TimelineComponent>
</template>


<script>
import TimelineComponent from './components/TimelineComponent.vue';
export default {
  name: 'App',
  components: {
    TimelineComponent
  },
  methods: {
    isMobile() {
        if( /Android|webOS|iPhone|iPod|BlackBerry/i.test(navigator.userAgent) ) {
          return true
        }
        return false
      },
  }
};
</script>


<style>
@font-face {
    font-family: "title_font";
    src: url('~@/assets/Holligate Signature Demo.ttf');
}

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 60px;
}

.waze-logo {
  display: block;
  flex-shrink: 0;
  width: 36px;
  height: 32px;
}
</style>

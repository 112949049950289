<template>
    <div class="backgrounad">
        <img src="../assets/background_4.png" class="background">
    </div>
    <div class="page-title">
      Or Y Nico
    </div>
    <div class="page-info">
        02.04.2024 | כ"ג באדר ב' | יום שלישי
    </div>
    <div class="timeline-container">
      <div class="timeline-line"></div>
      <div v-for="(event, index) in events" :key="index" class="waypoint two-col">
          <div v-if="indexEven(index)" />
          <Waypoint
            @enter="onEnter"
            @leave="onLeave">
            
            <div :class="['event', eventClass(index)]">
              <div v-if="indexEven(index)" class="line-connector"></div>
              <div v-if="!indexEven(index)" />
              <div class="event-content" ref="infoBox">
                <img :src="getImgUrl(event.icon)" class="event-icon" alt="Event Icon">
                <div class="event-time">{{ event.time }}</div>
                <div class="event-title">{{ event.title }}</div>
                <div class="event-extra">
                  <slot :name="event.slotName"></slot>
                </div>
              </div>
              <div v-if="!indexEven(index)" class="line-connector"></div>
            </div>
            
          </Waypoint>
          <div v-if="!indexEven(index)" />
      </div>

    </div>
    <div class="event-content bottom-event">
        <img :src="getImgUrl('taxi.png')" class="event-icon" alt="Event Icon">
        <div class="event-time"></div>
        <div class="event-title">חוזרים שבורים</div>
        <div class="event-extra">
          <slot name="whatsapp_group"></slot>
        </div>
      </div>
    <div class="flaticon">
        <a href="https://www.flaticon.com/free-icons/fleet" title="fleet icons">Fleet icons created by Culmbio - Flaticon</a>
        <br/>
        <a href="https://www.flaticon.com/free-icons/steak" title="steak icons">Steak icons created by lapiyee - Flaticon</a>
        <br/>
        <a href="https://www.flaticon.com/free-icons/jewish" title="jewish icons">Jewish icons created by Freepik - Flaticon</a>
        <br/>
        <a href="https://www.flaticon.com/free-icons/food-and-restaurant" title="food and restaurant icons">Food and restaurant icons created by Freepik - Flaticon</a>
        <br/>
        <a href="https://www.flaticon.com/free-icons/whatsapp" title="whatsapp icons">Whatsapp icons created by riajulislam - Flaticon</a>
    </div>
  </template>

  <script>
  import { Waypoint } from 'vue-waypoint';

  export default {
    components: {
      Waypoint,
    },
    data() {
      return {
        events: [
          {
            time: '',
            title: 'שוברים את הקלאץ',
            icon: 'traffic-jam.png',
            slotName: 'eventExtra1'
          },
          {
            time: '18:30',
            title: 'שוברים את הקרח',
            icon: 'champagne.png',
            slotName: 'eventExtra2'
          },
          {
            time: '19:30',
            title: 'שוברים את הכוס',
            icon: 'glass.png',
            slotName: 'eventExtra2'
          },
          {
            time: '20:30',
            title: 'שוברים את הדיאטה',
            icon: 'protein.png',
            slotName: 'eventExtra3'
          },
          {
            time: '21:30',
            title: 'שוברים את הרחבה',
            icon: 'disco-ball.png',
            slotName: 'eventExtra4'
          },
          // ... other events
        ],
        bottomEventWidth: 0,
      };
    },
    methods: {
      eventClass(index) {
        return index % 2 === 0 ? 'left-side' : 'right-side';
      },
      indexEven(index) {
        return index % 2 == 0
      },
      getImgUrl(pic) {
        return require('../assets/'+pic)
      },
      onEnter(waypoint) {
        // Handle waypoint enter
        console.log(waypoint);
        return null;
      },
      onLeave(waypoint) {
        // Handle waypoint leave
        console.log(waypoint);
        this.matchHeight();
        return null;
      },
    },
  };
  </script>
  
  <style scoped>

.background {
    position: fixed;
    top: 0px;
    left: 0px;
    /*background-image: url("../assets/background_1.webp");*/

    background-repeat: no-repeat;
    background-attachment: fixed;
    background-position: center;
    /*background-size: cover;*/
      /* Full height */
    width: 100%; 
    height: 100%;
    object-fit: cover;
    z-index: -1;
}

.bottom-event {
  position: relative;
  width: 250px;
  left: 50%;
  transform: translate(-50%, -20%);
}

 .page-title {
  font-family: "title_font";
  font-size: 10vw;
  /*color:#ba9640 ;*/
  color: white;
  text-shadow: rgb(0, 0, 0) 1px 0 10px;
  /*-webkit-text-stroke-width: 1px;
  -webkit-text-stroke-color: black;*/
 }

 .page-info {
  margin-bottom: 30px;
  font-size: larger;
  font-weight: bold;
  text-shadow: rgb(75, 125, 129) 0px 0 10px;
 }

  .flaticon {
    font-size: xx-small;
  }
  .timeline-container {
    position: relative;
    width: 100%;
  }
  
  .timeline-line {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 50%;
    width: 3px;
    background: #333;
    background-image: linear-gradient(to bottom, #333 33%, rgba(255,255,255,0) 0%);
    background-position: right;
    background-size: 4px 10px;
    background-repeat: repeat-y;
  }

  .two-col {
    display: grid;
    grid-template-columns: 1fr 1fr;
  }
  
  .waypoint {
    width: 100%;
    /*display: flex;*/
    justify-content: center;
    margin-bottom: 20px;
  }
  
  .event {
    /*position: relative; */
    padding-top: 20px;
    padding-bottom: 20px;
    box-sizing: border-box;
    display: grid;
    /* flex-direction: column; */
    align-items: center;
  }
  
  .left-side {
    /*align-items: flex-end;*/
    position: 50%;
    padding-right: 20%;
    
    grid-template-columns: 0.75fr 250px;
  }
  
  .right-side {
    position: 5%;
    padding-left: 20%;
    grid-template-columns: 0.25fr 250px 0.75fr;
    /*align-items: flex-start;*/
  }
  
  .line-connector {
    /* position: absolute; */
    /* width: 2px; */
    height: 2px;
    background: #333;
    z-index: 1;
  }

  @media screen and (max-width: 800px) {
  .left-side {
        grid-template-columns: 8% 1fr;
    }
   .right-side {
        grid-template-columns: 0% 1fr 8%;
   }
   .bottom-event {
      width: 30%;
   } 
  }
  
  /*    .left-side .line-connector {
    left: 50%;
    width: 50%;
  }
  
  .right-side .line-connector {
    left: 50%;
  }*/
  
  .event-content {
    z-index: 2;
    background: white;
    padding: 10px;
    border-radius: 4px;
    box-shadow: 0 2px 4px rgba(0,0,0,0.2);
  }
  
  .event-icon {
    width: 50px;
    height: 50px;
  }
  
  .event-time {
    font-weight: bold;
  }
  
  .event-title {
    font-size: 1.2em;
  }
  
  .event-extra {
    margin-top: 10px;
  }
  </style>
  